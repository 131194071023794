import * as React from "react";
import { Flex, Box } from "reflexbox";
import Layout from "../components/layout";
import { Container, GalleryContainer } from "../components/layout/container";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Gallery from "../components/gallery";
import { Proposal } from "../components/proposal/Proposal";
import { useState } from "react";
import Modal, { ModalData } from "../components/modal/Modal";
import { Ornament } from "../components/layout/ornament";
import { Center, H1 } from "../components/typography/styled/typography";
import { Lang, Translate } from "../components/tr";
import thumbnailSrc from "../../static/thumbnail_oil.jpg";

const OilGallery = ({ data, pageContext: { lang = Lang.cs }, location }) => {
  const [modalData, setModalData] = useState<ModalData | null>(null);
  /*const imgSets: ImgSet[] = useMemo(
    () =>
      data.allMarkdownRemark.edges.map(({ node }) =>
        getImageData(data.gallery, node)
      ),
    []
  );*/

  return (
    <Layout lang={lang} location={location}>
      <SEO lang={lang} title={"Oil Gallery"} image={thumbnailSrc} />
      <Modal
        lang={lang}
        title={modalData !== null ? modalData.title : ""}
        data={modalData}
        onClose={() => setModalData(null)}
      />
      <Container>
        {/*
        <Ornament />
        <Center>
          <H1>
            <Translate text={"Featured artwork for sale"} />
          </H1>
          <Flex justifyContent={"center"}>
            <Box width={[1, 2 / 3, 2 / 3]}>
              <cite>
                “
                <Translate
                  text={
                    "Nature is the source of all true knowledge. She has her own logic, her own laws, she has no effect without cause nor invention without necessity."
                  }
                />
                ” &mdash;Leonardo da Vinci
              </cite>
            </Box>
          </Flex>
        </Center>
        {imgSets.map((set) => {
          return (
            <FeaturedInSection
              key={set.image.alt}
              imgSet={set}
              onOpen={() => setModalData(set)}
              lang={lang}
            />
          );
        })}
        */}
      </Container>


      <GalleryContainer>
        <Ornament />
        <Center>
          <H1>
            <Translate text={"Sales Gallery"} />
          </H1>

          <Flex justifyContent={"center"}>
            <Box width={[1, 2 / 3, 2 / 3]} mb={[3]}>
              <cite>
                “
                <Translate
                  text={
                    "Nature is the source of all true knowledge. She has her own logic, her own laws, she has no effect without cause nor invention without necessity."
                  }
                />
                ” &mdash;Leonardo da Vinci
              </cite>
            </Box>
          </Flex>
        </Center>
        <Flex flexWrap={"wrap"}>
          <Box width={[1, 1, 1, 1]} py={3} mt={[3, 0, 0, 0]} mb={[3]}>
            <Gallery
              nodes={data.allFile.nodes}
              meta={data.galleryMeta.nature}
              onOpenDetail={setModalData}
            />
          </Box>
        </Flex>
      </GalleryContainer>
      <Proposal />
    </Layout>
  );
};

export default OilGallery;

export const galleryNatureQuery = graphql`
  query galleryNatureQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: "**/oil-gallery/*.md" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          excerpt(pruneLength: 410)
          html
          frontmatter {
            date
            year
            title
            tags
            directory
            dimension
            material
            variants {
              name
              price
            }
          }
        }
      }
    }

    gallery: allFile(
      filter: { relativePath: { regex: "gallery/nature/.*.jpg/i" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        base
        relativePath
        childImageSharp {
          id
          original {
            width
            height
            src
          }
          fluid(maxWidth: 600, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
          big: fluid(maxWidth: 1024, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 623, height: 450, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    galleryMeta: galleryYaml {
      nature {
        name
        availability
        title
        year
        dimension
        material
        variants {
          name
          price
        }
      }
    }

    allFile(
      filter: { relativePath: { glob: "gallery/nature/*.jpg" } }
      sort: { fields: [name], order: DESC }
    ) {
      nodes {
        base
        childImageSharp {
          id
          original {
            width
            height
            src
          }
          fixed(width: 400, height: 400, quality: 100) {
            width
            height
            src
          }
        }
      }
    }
  }
`;
